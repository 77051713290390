import React from 'react';
import './App.css';
import { API_URL } from "./services/api/httpService";

function App() {
  return (
    <div className="App">
      <div className="flex flex-col space-between h-full space-y-[200px]">
        <p className="text-2xl">Download Insights Excel Files</p>
        <div className="flex space-x-[40px] text-lg">
          <a className="border rounded p-4 hover:bg-sky-800" href={`${API_URL}/api/report/download?type=customer`} download>Customer Insights</a>
          <a className="border rounded p-4 hover:bg-sky-800" href={`${API_URL}/api/report/download?type=transaction`} download>Transaction Insights</a>
        </div>
      </div>
    </div>
  );
}

export default App;
